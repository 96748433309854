import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import HeaderFormActions from 'components/BizlyOS/HeaderFormActions';
import { BasicInfoBox } from 'components/BizlyOS/InfoBoxes';
import CurrencyField from 'components/BizlyOS/InputFields/CurrencyField';
import InputField from 'components/BizlyOS/InputFields/InputField';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry, useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { z } from 'zod';
import { CURRENCY_MODAL_DESCRIPTION, DEFAULT_CURRENCY_CODE, numericSchema } from '../../utils';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    borderRadius: spacing(1),
    padding: spacing(2.5),
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const Title = styled(Body1)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 500,
    lineHeight: '21px',
}));

const ProposalRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(1.25),
}));

const RoomBlockSchema = z.object({
    resortFee: numericSchema(),
    currencyCode: z.string().min(1, { message: 'Currency code is required' }),
    occupancyTax: numericSchema({ max: 100 }),
    salesTax: numericSchema({ max: 100, optional: true }),
    serviceCharge: numericSchema({ max: 100, optional: true }),
    gratuity: numericSchema({ max: 100, optional: true }),
    deposit: numericSchema({ optional: true }),
    depositType: z.union([z.literal('percentage'), z.literal('amount')]).optional(),
});

type RoomBlockFormData = z.infer<typeof RoomBlockSchema>;

export function TaxAndFees() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const { proposal, guestRooms = [], eventSpaces = [] } = proposalInquiry || {};

    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);

    const {
        control,
        formState: { errors, isDirty },
        setValue,
        handleSubmit,
        watch,
        reset,
    } = useForm<RoomBlockFormData>({
        resolver: zodResolver(RoomBlockSchema),
        mode: 'onBlur',
        defaultValues: {
            currencyCode: proposal?.currency?.code || DEFAULT_CURRENCY_CODE,
            resortFee: proposal?.resortFee || 0,
            occupancyTax: proposal?.occupancyTax || 0,
            salesTax: proposal?.salesTax || 0,
            serviceCharge: proposal?.serviceCharge || 0,
            gratuity: proposal?.gratuity || 0,
        },
    });

    const onSave = (data: RoomBlockFormData) => {
        const currency = proposalInquiry?.options.currencies?.find(c => c.code === data.currencyCode);

        const proposal = {
            ...proposalInquiry?.proposal,
            occupancyTax: data.occupancyTax,
            resortFee: data.resortFee,
            currencyCode: data.currencyCode,
            currencyId: currency?.id as number,
            salesTax: data.salesTax,
            serviceCharge: data.serviceCharge,
            gratuity: data.gratuity,
        };

        updateProposalInquiry.mutate({ proposal }, { onSuccess: () => reset(data) });
    };

    const [currencyCode] = watch(['currencyCode', 'depositType']);

    const isFilled = proposal?.resortFee && proposal?.occupancyTax;

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2.5}>
                {!isFilled && (
                    <BasicInfoBox
                        color={getColor(EColors.primaryAction)}
                        message="Add or edit your venue’s taxes and fees. "
                    />
                )}

                {guestRooms.length > 0 && (
                    <BorderedContainer>
                        <Title>Guest Room Taxes & Fees</Title>
                        <ProposalRow>
                            <Controller
                                name="occupancyTax"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputField
                                            {...field}
                                            required
                                            error={errors.occupancyTax}
                                            label="Occupancy Tax"
                                            placeholder="0"
                                            type="number"
                                            suffix="%"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="resortFee"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <CurrencyField
                                            type="number"
                                            {...field}
                                            required
                                            currencyCode={currencyCode}
                                            onCurrencyCodeChange={code =>
                                                setValue('currencyCode', code, {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                })
                                            }
                                            error={errors.resortFee}
                                            label="Resort Fee Total"
                                            currencyModalDescription={CURRENCY_MODAL_DESCRIPTION}
                                            currencyOptions={proposalInquiry?.options.currencies || []}
                                        />
                                    );
                                }}
                            />
                        </ProposalRow>
                    </BorderedContainer>
                )}

                {eventSpaces.length > 0 && (
                    <BorderedContainer>
                        <Title>Event Taxes & Fees</Title>
                        <ProposalRow sx={{ gridTemplateColumns: 'repeat(3,1fr)' }}>
                            <Controller
                                name="salesTax"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputField
                                            {...field}
                                            error={errors.salesTax}
                                            label="Sales Tax"
                                            placeholder="0"
                                            type="number"
                                            suffix="%"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="serviceCharge"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputField
                                            {...field}
                                            error={errors.serviceCharge}
                                            label="Service Charge"
                                            placeholder="0"
                                            type="number"
                                            suffix="%"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="gratuity"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputField
                                            {...field}
                                            error={errors.gratuity}
                                            label="Gratuity"
                                            placeholder="0"
                                            type="number"
                                            suffix="%"
                                        />
                                    );
                                }}
                            />
                        </ProposalRow>
                    </BorderedContainer>
                )}

                {/* 
                NOTE: Commented for now until the API is ready to handel deposit.
                <BorderedContainer>
                    <Title>Deposit Requirement</Title>
                    <ProposalRow sx={{ gridTemplateColumns: '1fr 2fr' }}>
                        <Controller
                            name="depositType"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SelectField
                                        {...field}
                                        error={errors.depositType}
                                        options={[
                                            { value: 'percentage', label: 'Percentage' },
                                            { value: 'amount', label: 'Amount' },
                                        ]}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="deposit"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        error={errors.deposit}
                                        placeholder="0"
                                        type="number"
                                        {...(depositType === 'percentage' ? { suffix: '%' } : {})}
                                        {...(depositType === 'amount' ? { prefix: proposal?.currency?.symbol } : {})}
                                    />
                                );
                            }}
                        />
                    </ProposalRow>
                </BorderedContainer> 
                */}
            </Box>
            <HeaderFormActions
                state="edit"
                isSaving={updateProposalInquiry.isLoading}
                isChanges={isDirty}
                onSave={handleSubmit(onSave)}
                onCancel={() => reset()}
            />
        </>
    );
}
