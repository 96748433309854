import { Box, styled } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';

const InfoContainer = styled(Box)(({ theme: { spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    gap: spacing(1.25),
}));

export const RoomBlockInfo = ({
    infos = [],
    backgroundColor = getColor(EColors.pureBlack),
    lableColor = getColor(EColors.pureWhite),
    valueColor = getColor(EColors.pureWhite),
    dividerColor = getColor(EColors.bizlyOSBorder),
}: {
    infos: { label: string; value: string | number }[];
    backgroundColor?: string;
    lableColor?: string;
    valueColor?: string;
    dividerColor?: string;
}) => {
    return (
        <InfoContainer sx={{ backgroundColor }}>
            {infos.map((info, index) => (
                <Box
                    flex={1}
                    borderLeft={index === 0 ? 'none' : `0.5px solid ${dividerColor}`}
                    paddingLeft={index === 0 ? 0 : 2.5}
                >
                    <Body2 sx={{ color: lableColor }}>{info.label}</Body2>
                    <Body1 sx={{ color: valueColor }}>{info.value}</Body1>
                </Box>
            ))}
        </InfoContainer>
    );
};
