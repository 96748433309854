import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Switch } from 'components/BizlyOS/InputFields/SwitchField';
import { DEFAULT_CURRENCY_SYMBOL } from 'components/BizlyOS/Proposals/utils';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { TESBooking } from 'components/ProposalForm/types';
import { useGetEventSpaceQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddSpaceDetailsDrawer, ProposedVenueSpace } from './AddSpaceDetailsDrawer';
import { EventSpacePricingData } from './EventSpacePricingData';
import { ReuseDetails } from './ReuseDetails';

const PossibilityContainer = styled(Box)(({ theme: { spacing, getColor, EColors, shape } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    padding: spacing(2.25),
    borderRadius: shape.borderRadius,
}));

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const TitleContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

type EventSpaceProposedDetailsProps = {
    eventSpace: TESBooking;
    eventSpaceIndex: number;
};

export function EventSpaceProposedDetails({ eventSpace, eventSpaceIndex }: EventSpaceProposedDetailsProps) {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: venueEventSpaces, isLoading: venueEventSpacesLoading } = useGetEventSpaceQuery(venueId);
    const { data: proposalInquiry, isLoading: proposalInquiryLoading } = useGetProposalInquiry(venueId, proposalId);
    const proposal = proposalInquiry?.proposal;
    const eventSpaces = proposalInquiry?.eventSpaces;

    const [isPossible, setIsPossible] = useState(true);
    const [proposedVenueSpace, setProposedVenueSpace] = useState<Bizly.OsEventSpace>();

    const [isAddSpaceDetailsDrawerOpen, setIsAddSpaceDetailsDrawerOpen] = useState(false);

    const onAddSpaceDetails = () => setIsAddSpaceDetailsDrawerOpen(true);

    useEffect(() => {
        if (!venueEventSpaces || !eventSpace.proposedVenueSpaceId) return;

        const space = venueEventSpaces.find(space => space.id === eventSpace.proposedVenueSpaceId);

        if (!space) return;

        setProposedVenueSpace(space);
        setIsPossible(true);
    }, [venueEventSpaces, eventSpace.proposedVenueSpaceId]);

    if (venueEventSpacesLoading || proposalInquiryLoading) return null;

    const totaleventSpaceCost = (eventSpace.proposedRatePerPerson || 0) * (eventSpace.proposedMinGuests || 0);

    return (
        <>
            <PossibilityContainer>
                <Body2 fontWeight={500}>Can you accommodate the requests?</Body2>
                <Switch
                    checked={isPossible}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIsPossible(event.target.checked);
                    }}
                />
            </PossibilityContainer>

            {isPossible && (
                <>
                    {!proposedVenueSpace ? (
                        <BorderedContainer>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Body1 fontWeight={500}>Add Space Details</Body1>
                                <Button variant="text" sx={{ p: '0px 5px' }} onClick={onAddSpaceDetails}>
                                    + Add Details
                                </Button>
                            </Box>
                        </BorderedContainer>
                    ) : (
                        <>
                            <BorderedContainer>
                                <TitleContainer>
                                    <Body1 fontWeight={600}>Space Details</Body1>
                                    <Button sx={{ pt: 0, pb: 0 }} variant="text" onClick={onAddSpaceDetails}>
                                        Change / Edit
                                    </Button>
                                </TitleContainer>
                                <ProposedVenueSpace proposedVenueSpace={proposedVenueSpace} />
                                <EventSpacePricingData
                                    useDdr={Boolean(proposal?.useDdr)}
                                    currencySymbol={proposal?.currency?.symbol || DEFAULT_CURRENCY_SYMBOL}
                                    eventSpace={eventSpace}
                                    totaleventSpaceCost={totaleventSpaceCost}
                                />
                            </BorderedContainer>
                            {(eventSpaces?.length || 0) > 1 && (
                                <ReuseDetails
                                    eventSpaceIndex={eventSpaceIndex}
                                    currentEventSpace={eventSpace}
                                    eventSpaces={eventSpaces || []}
                                    proposal={proposal}
                                    isLastSpace={eventSpaceIndex + 1 === eventSpaces?.length}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <AddSpaceDetailsDrawer
                drawerOpen={isAddSpaceDetailsDrawerOpen}
                proposedVenueSpace={proposedVenueSpace}
                eventSpace={eventSpace}
                onClose={() => setIsAddSpaceDetailsDrawerOpen(false)}
            />
        </>
    );
}
