import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { INQUIRY_PROPOSAL_PATH } from '../../InquirySubNavigation';

const ContactInfo = styled(Box)(({ theme: { spacing, getColor, EColors } }) => ({
    padding: spacing(2.5, 0),
    borderTop: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    marginBottom: spacing(2.5),
}));

export const ProposalWon = ({ venueId, proposalId }: { venueId: string; proposalId: string }) => {
    const navigate = useNavigate();

    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const { client, booking } = proposalInquiry || {};

    const navigateToProposal = () => {
        if (venueId && proposalId) {
            navigate(`/os/venues/${venueId}/proposals/${proposalId}/${INQUIRY_PROPOSAL_PATH}`);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <CheckCircleIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body1 fontWeight={600}>Booking Confirmed!</Body1>
            </Box>
            <Body2 marginBottom={2.5}>
                Congrats! The contract has been signed and this booking is confirmed. You will work directly with{' '}
                {`${client?.firstName ?? ''} ${client?.lastName ?? ''}`} to finalize the remaining details and payment.
            </Body2>
            <ContactInfo>
                <Body2 fontWeight={600} marginBottom={1.25} lineHeight="normal">
                    Contact Info:
                </Body2>
                <Body2>{`${client?.firstName ?? ''} ${client?.lastName ?? ''}`}</Body2>
                <Body2>{client?.email ?? ''}</Body2>
            </ContactInfo>
            <Button
                fullWidth
                size="small"
                sx={{ marginBottom: '.625rem', height: '2.5rem' }}
                onClick={navigateToProposal}
            >
                View Proposal
            </Button>
            {booking?.contractUrl && (
                <a href={booking.contractUrl} download target="_blank" rel="noopener noreferrer">
                    <Button fullWidth variant="outline" sx={{ height: '2.5rem' }} onClick={() => {}}>
                        Download Signed Contract
                    </Button>
                </a>
            )}
        </>
    );
};
