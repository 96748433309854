import { Box, styled } from '@mui/material';
import ListingBody from 'components/BizlyOS/Listings/ListingBody';
import ListingHeader from 'components/BizlyOS/Listings/ListingHeader';
import ListingInsights from 'components/BizlyOS/Listings/ListingInsights';
import ListingsSubNavigation from 'components/BizlyOS/Listings/ListingsSubNavigation';
import { useGetListingQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Container = styled(Box)({
    height: '100%',
    boxSizing: 'border-box',
});

const headerHeight = '60px';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    height: headerHeight,
}));

const BodyContainer = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${headerHeight})`,
    boxSizing: 'border-box',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '220px minmax(540px,730px) minmax(220px,380px)',
    gridTemplateRows: '1fr',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
    overflowX: 'auto',
}));

export default function Listing() {
    const { listingId } = useParams() as { listingId: string };

    const { data: listing, isLoading } = useGetListingQuery(listingId);

    // We'll need another way to set the venueId from the Inquiries page
    const { setVenueId } = useBizlyOSUser();

    useEffect(() => {
        if (listingId) {
            setVenueId(listingId);
        }
    }, [listingId, setVenueId]);

    return (
        <Container>
            <HeaderContainer>
                <ListingHeader state="edit" propertyTitle={listing?.name || ''} tempClosed={listing?.tempClosed} />
            </HeaderContainer>
            <BodyContainer>
                <ListingsSubNavigation listing={listing} />
                <ListingBody listing={listing} isLoading={isLoading} />
                <ListingInsights />
            </BodyContainer>
        </Container>
    );
}
