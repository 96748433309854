import { TFBRequest } from 'components/ProposalForm/EventSpacesForm/utils';
import { TESBooking, TGRBooking, TProposal, TProposalForm, TProposalOptions } from 'components/ProposalForm/types';
import { z } from 'zod';

export const DATE_FORMAT = 'MMM D, YYYY';
export const DEFAULT_ROOM_TYPE = 'Single';
export const DEFAULT_CURRENCY_CODE = 'USD';
export const DEFAULT_CURRENCY_SYMBOL = '$';
export const DEFAULT_COMMISSION = 10;

export const IMPERIAL_UNIT = 'Sq. Ft';
export const METRIC_UNIT = 'Sq. Mt';
export const FIRST_INDEX = 0;
export const SECOND_INDEX = 1;
export const THIRD_INDEX = 2;

export const CURRENCY_MODAL_DESCRIPTION =
    'Update the currency for the entire proposal. Previously entered prices values will not be converted.';

export type TypeOfRoomTypes = 'Single' | 'Double' | 'Suite';

export const hasAllRoomBlockBeenFilled = (guestRooms: TGRBooking[] = []): boolean => {
    // Check if all rooms have required fields filled
    return guestRooms.every(room => room.proposedRoomCount && room.proposedRoomRate);
};

export const hasEventSpaceBeenFilled = (eventSpaces: TESBooking, useDdr = false): boolean => {
    const {
        proposedVenueSpaceId,
        proposedSetupId,
        proposedRoomRate,
        proposedFbMinimum,
        proposedRatePerPerson,
        proposedMinGuests,
    } = eventSpaces;

    // Mandatory fields for all cases
    if (!proposedVenueSpaceId || !proposedSetupId) {
        return false;
    }

    // DDR-specific checks
    if (useDdr) {
        return Boolean(proposedRatePerPerson) && Boolean(proposedMinGuests);
    }

    // Non-DDR checks
    return Boolean(proposedRoomRate) && Boolean(proposedFbMinimum);
};

export const hasAllEventSpaceBeenFilled = (eventSpaces: TESBooking[] = [], useDdr = false): boolean => {
    return eventSpaces.every(eventSpace => hasEventSpaceBeenFilled(eventSpace, useDdr));
};

export const getProposalNotValid = (proposalInquiry: TProposalForm): boolean => {
    const { proposal, contact, guestRooms = [], eventSpaces = [] } = proposalInquiry;

    // Early returns for basic validation failures
    if (!contact || !proposal || !proposal.expiryDate) return true;

    // Guest room validation
    if (
        guestRooms.length > 0 &&
        (!hasAllRoomBlockBeenFilled(guestRooms) || !proposal.occupancyTax || !proposal.resortFee)
    )
        return true;

    // Event space validation
    if (eventSpaces.length > 0 && !hasAllEventSpaceBeenFilled(eventSpaces, Boolean(proposalInquiry?.proposal?.useDdr)))
        return true;

    return false;
};

const calculateGuestRoomsTotalRate = (guestRooms: TGRBooking[]): number => {
    return guestRooms.reduce((total, guestRoom) => {
        const { proposedRoomCount, proposedRoomRate } = guestRoom;
        const dailyCost = proposedRoomCount && proposedRoomRate ? proposedRoomCount * proposedRoomRate : 0;
        return total + dailyCost;
    }, 0);
};

export const calculateEventSpaceFbMinimum = (eventSpaces: TESBooking[]): number => {
    if (!eventSpaces) return 0;
    return eventSpaces.reduce((total, eventSpace) => total + (eventSpace.proposedFbMinimum ?? 0), 0);
};

const calculateEventSpaceRate = (eventSpace: TESBooking, useDdr: boolean): number => {
    if (!useDdr) return eventSpace.proposedRoomRate ?? 0;

    const { proposedRatePerPerson, proposedMinGuests } = eventSpace;
    return proposedRatePerPerson && proposedMinGuests ? proposedRatePerPerson * proposedMinGuests : 0;
};

export const calculateEventSpaceTotalRate = (eventSpaces: TESBooking[], useDdr: boolean): number => {
    if (!eventSpaces) return 0;
    return eventSpaces.reduce((total, eventSpace) => total + calculateEventSpaceRate(eventSpace, useDdr), 0);
};

const calculatePercentageCharge = (total: number, percentage: number | undefined | null): number =>
    (total / 100) * (percentage || 0);

export const calculateGuestRoomsData = (guestRooms: TGRBooking[] = [], proposal: Partial<TProposal> | undefined) => {
    const roomRateTotal = calculateGuestRoomsTotalRate(guestRooms);

    const occupancyTax = calculatePercentageCharge(roomRateTotal, proposal?.occupancyTax);

    const totalResortFee = (proposal?.resortFee || 0) * guestRooms.length;

    const roomblockTotal = roomRateTotal + occupancyTax + totalResortFee;

    return { roomRateTotal, occupancyTax, totalResortFee, roomblockTotal };
};

export const calculateEventSpacesData = (eventSpaces: TESBooking[] = [], proposal: Partial<TProposal> | undefined) => {
    const useDdr = Boolean(proposal?.useDdr);

    const eventSpacesRateTotal = calculateEventSpaceTotalRate(eventSpaces, useDdr);

    const eventSpaceFbMinimum = !useDdr ? calculateEventSpaceFbMinimum(eventSpaces) : 0;

    const salesTax = calculatePercentageCharge(eventSpacesRateTotal, proposal?.salesTax);
    const serviceCharge = calculatePercentageCharge(eventSpacesRateTotal, proposal?.serviceCharge);
    const gratuity = calculatePercentageCharge(eventSpacesRateTotal, proposal?.gratuity);

    const eventSpacesTotal = eventSpacesRateTotal + eventSpaceFbMinimum + salesTax + serviceCharge + gratuity;

    return {
        eventSpacesRateTotal,
        eventSpaceFbMinimum,
        salesTax,
        serviceCharge,
        gratuity,
        eventSpacesTotal,
    };
};

export const getRoomSetup = (options: TProposalOptions | undefined, id: number | undefined | null) => {
    if (!id || !options?.spaceSetups) return '-';

    const roomSetup = options?.spaceSetups.find(rs => rs.id === id);

    if (roomSetup) return roomSetup.name;

    return '-';
};

export const getAvRequests = (options: TProposalOptions | undefined, ids: number[] | undefined | null): string[] => {
    if (!ids || ids.length === 0) return [];

    return ids.map(id => options?.avOptions.find(av => av.id === id)?.name).filter(Boolean) as string[];
};

export const getFbRequests = (
    options: TProposalOptions | undefined,
    fbRequests: TFBRequest[] | undefined
): string[] => {
    if (!fbRequests || fbRequests.length === 0) return [];

    return fbRequests
        .map(request => options?.fbOptions.find(fb => fb.id === request.fbOptionId)?.name)
        .filter(Boolean) as string[];
};

type NumericSchemaOPTionType = {
    min?: number;
    max?: number;
    minMessage?: string;
    maxMessage?: string;
    optional?: boolean;
};

export const numericSchema = (options: NumericSchemaOPTionType = {}) => {
    const {
        min = 0,
        max = Infinity,
        minMessage = 'Must be a positive number',
        maxMessage = 'Exceeds the maximum allowed limit',
        optional = false,
    } = options;

    const schema = z
        .union([
            // Handle strings (allow trimmed non-empty numeric strings)
            optional ? z.string().trim().optional() : z.string().trim().min(1, { message: 'Required' }),
            // Handle numbers directly
            z.number(),
        ])
        .transform(val => {
            // Clean strings (remove commas, currency symbols, spaces) before conversion
            return typeof val === 'string' ? Number(val.replace(/[,$\s]/g, '')) : val;
        })
        .pipe(
            z.coerce
                .number()
                .refine(v => !isNaN(v), { message: 'Must be a valid number' })
                .refine(v => isFinite(v), { message: 'Must be a finite number' })
                .refine(v => v >= min, { message: minMessage })
                .refine(v => v <= max, { message: maxMessage })
        );

    if (optional) return schema.optional();

    return schema;
};

export function getMaxRequestedGuests(eventSpaces: TESBooking[], guestRooms: TGRBooking[]): number {
    // Find max from eventSpaces
    const eventSpacesMax =
        eventSpaces.length > 0 ? Math.max(...eventSpaces.map(eventSpace => eventSpace.requestedGuests || 0)) : 0;

    // Find max from guestRooms
    const guestRoomsMax =
        guestRooms.length > 0 ? Math.max(...guestRooms.map(guestRoom => guestRoom.requestedGuests || 0)) : 0;

    // Return the overall maximum
    return Math.max(eventSpacesMax, guestRoomsMax);
}
