import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { BorderedContainer as Container } from 'components/BizlyOS/GridContainer';
import HeaderFormActions from 'components/BizlyOS/HeaderFormActions';
import { BasicInfoBox } from 'components/BizlyOS/InfoBoxes';
import InputField from 'components/BizlyOS/InputFields/InputField';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry, useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { z } from 'zod';
import { CommissionDetails } from '../../CommissionDetails';
import {
    DEFAULT_COMMISSION,
    calculateEventSpaceFbMinimum,
    calculateEventSpaceTotalRate,
    numericSchema,
} from '../../utils';

const BorderedContainer = styled(Container)(({ theme: { spacing } }) => ({
    borderWidth: '0.5px',
    padding: spacing(2.5),
}));

const InfoMessage = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    '& > span': {
        color: getColor(EColors.bizlyOSPrimary),
        fontWeight: 600,
    },
}));

const CommissionsSchema = z.object({
    guestRoomCommissionRate: numericSchema({ max: 100, optional: true }),
    eventSpaceCommissionRate: numericSchema({ max: 100, optional: true }),
    fbCommissionRate: numericSchema({ max: 100, optional: true }),
});

function convertToNumber(value: unknown) {
    if (typeof value === 'string') {
        const number = Number(value);
        if (isNaN(number)) return DEFAULT_COMMISSION;
        return number;
    }
    if (typeof value === 'number') return value;
    return DEFAULT_COMMISSION;
}

type CommissionsFormData = z.infer<typeof CommissionsSchema>;

export function Commissions() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const { proposal, guestRooms = [] } = proposalInquiry || {};

    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);

    const { showEventSpaceCommission, showFbCommission } = useMemo(() => {
        const eventSpaces = proposalInquiry?.eventSpaces || [];
        const useDdr = Boolean(proposalInquiry?.proposal?.useDdr);

        return {
            showEventSpaceCommission: calculateEventSpaceTotalRate(eventSpaces, useDdr) > 0,
            showFbCommission: calculateEventSpaceFbMinimum(eventSpaces) > 0,
        };
    }, [proposalInquiry?.eventSpaces, proposalInquiry?.proposal?.useDdr]);

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
        reset,
    } = useForm<CommissionsFormData>({
        resolver: zodResolver(CommissionsSchema),
        defaultValues: {
            guestRoomCommissionRate: convertToNumber(proposal?.guestRoomCommissionRate),
            eventSpaceCommissionRate: convertToNumber(proposal?.eventSpaceCommissionRate),
            fbCommissionRate: convertToNumber(proposal?.fbCommissionRate),
        },
        mode: 'onChange',
    });

    const onSubmit = (data: CommissionsFormData) => {
        const { guestRoomCommissionRate, eventSpaceCommissionRate, fbCommissionRate } = data;
        const proposal = {
            ...proposalInquiry?.proposal,
            guestRoomCommissionRate: guestRoomCommissionRate || DEFAULT_COMMISSION,
            eventSpaceCommissionRate: eventSpaceCommissionRate || DEFAULT_COMMISSION,
            fbCommissionRate: fbCommissionRate || DEFAULT_COMMISSION,
            commissionable: Boolean(guestRoomCommissionRate),
            eventSpacesCommissionable: Boolean(eventSpaceCommissionRate),
            fbCommissionable: Boolean(fbCommissionRate),
        };

        updateProposalInquiry.mutate({ proposal }, { onSuccess: () => reset(data) });
    };

    if (isLoading || !proposalInquiry) return;

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2.5}>
                {Boolean(proposal?.bizlyCollectsCommissions) && (
                    <CommissionDetails documentUrl={proposal?.msaDocumentUrl || ''} />
                )}
                <BasicInfoBox
                    alignItems="flex-start"
                    backgroundColor={getColor(EColors.primaryAction, 0.1)}
                    color={getColor(EColors.primaryAction)}
                    message={
                        <InfoMessage>
                            Add your commission rates below. Please refer to the <span>attached document</span> or reach
                            out to the NSO for this client.
                        </InfoMessage>
                    }
                />
                {guestRooms.length > 0 && (
                    <BorderedContainer>
                        <Controller
                            name="guestRoomCommissionRate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        label="Room Block Commission Rate"
                                        type="number"
                                        suffix="%"
                                        error={errors.guestRoomCommissionRate}
                                    />
                                );
                            }}
                        />
                    </BorderedContainer>
                )}

                {showEventSpaceCommission && (
                    <BorderedContainer>
                        <Controller
                            name="eventSpaceCommissionRate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        label="Meeting / Event Space Commission Rate"
                                        type="number"
                                        suffix="%"
                                        error={errors.eventSpaceCommissionRate}
                                    />
                                );
                            }}
                        />
                    </BorderedContainer>
                )}

                {showFbCommission && (
                    <BorderedContainer>
                        <Controller
                            name="fbCommissionRate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        label="F&B Commission Rate"
                                        type="number"
                                        suffix="%"
                                        error={errors.fbCommissionRate}
                                    />
                                );
                            }}
                        />
                    </BorderedContainer>
                )}
            </Box>
            <HeaderFormActions
                state="edit"
                isSaving={isSubmitting || updateProposalInquiry.isLoading}
                isChanges={isDirty}
                onSave={handleSubmit(onSubmit)}
                onCancel={() => reset()}
            />
        </>
    );
}
