import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { formatCurrency } from 'utils/currency';

const RoomBlockRow = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing(2.5),
    borderTop: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const RoomBlockCell = styled(Body1)({
    flex: 1,
    lineHeight: 'normal',
});

const RoomBlockHeaderCell = styled(RoomBlockCell)({
    fontWeight: 500,
});

type RoomContentProps = {
    roomBlocks: {
        id: string;
        type: string;
        roomCount: number;
        dailyRate: number;
        currencySymbol: string;
        onAction: () => void;
    }[];
};

export const RoomContent = ({ roomBlocks = [] }: RoomContentProps) => {
    return (
        <>
            <RoomBlockRow>
                <RoomBlockHeaderCell>Room Type</RoomBlockHeaderCell>
                <RoomBlockHeaderCell>QTY</RoomBlockHeaderCell>
                <RoomBlockHeaderCell>Daily Rate</RoomBlockHeaderCell>
                <RoomBlockHeaderCell>Total</RoomBlockHeaderCell>
                <Box width={90} />
            </RoomBlockRow>
            {roomBlocks.map(room => (
                <RoomBlockRow>
                    <RoomBlockCell>{room.type}</RoomBlockCell>
                    <RoomBlockCell>{room.roomCount}</RoomBlockCell>
                    <RoomBlockCell>
                        {formatCurrency({
                            value: room.dailyRate,
                            symbol: room.currencySymbol,
                            decimals: 0,
                        })}
                    </RoomBlockCell>

                    <RoomBlockCell>
                        {formatCurrency({
                            value: room.roomCount * room.dailyRate,
                            symbol: room.currencySymbol,
                            decimals: 0,
                        })}
                    </RoomBlockCell>
                    <Button variant="text" sx={{ height: '20px', width: '90px', padding: 0 }} onClick={room.onAction}>
                        {room.dailyRate && room.roomCount ? 'View Details' : 'Add Details'}
                    </Button>
                </RoomBlockRow>
            ))}
        </>
    );
};
