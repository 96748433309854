import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Box, styled } from '@mui/material';
import { InviteStaffDrawer } from 'components/BizlyOS/InviteStaffDrawer';
import { StatusChip } from 'components/BizlyOS/StatusChip/StatusChip';
import { TProposalForm } from 'components/ProposalForm/types';
import { useStaffQuery } from 'hooks/queries/BizlyOS/useStaffQuery';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { formatDateRange } from 'utils/date_util';
import { Body2 } from '../../Typography/Typography';
import { getMaxRequestedGuests } from '../utils';
import { AssignInquirySideDrawer } from './AssignInquirySideDrawer/AssignInquirySideDrawer';

const primaryColor = getColor(EColors.bizlyOSPrimary);
const INQUIRIES_PATH = '/os/inquiries';

const ItemWrapper1 = styled(Box)(({ theme }) => ({
    marginRight: theme.spacing(2.5),
    alignSelf: 'center',
}));

const ItemWrapper2 = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    padding: '5px 10px',
    borderLeft: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    },
    '&.MuiSvgIcon-root': {
        fontSize: '1rem',
    },
}));

export const ProposalInquiryHeader = ({ proposalInquiry }: { proposalInquiry: TProposalForm }) => {
    const navigate = useNavigate();

    const { venueId } = useParams() as { venueId: string };

    const { data: staffMembers } = useStaffQuery(venueId);

    const [isAssignDrawerOpen, setIsAssignDrawerOpen] = useState<boolean>(false);
    const [inviteColleagueOpen, setInviteColleagueOpen] = useState(false);

    const { proposal, event, contact } = proposalInquiry;

    const guestCount = useMemo(
        () => getMaxRequestedGuests(proposalInquiry?.eventSpaces || [], proposalInquiry?.guestRooms || []),
        [proposalInquiry?.eventSpaces, proposalInquiry?.guestRooms]
    );

    const friendlyStatusName = proposal?.status?.friendlyName;
    const hasStaffMembers = staffMembers?.success && staffMembers?.staffMembers?.length > 0;

    const handleInviteOrAssign = () => {
        if (hasStaffMembers) {
            setIsAssignDrawerOpen(true);
        } else {
            setInviteColleagueOpen(true);
        }
    };

    return (
        <>
            <Box display="flex" alignItems="center">
                <ItemWrapper1>
                    <BackIcon onClick={() => navigate(INQUIRIES_PATH)} />
                </ItemWrapper1>
                <ItemWrapper1>
                    <Body2 fontWeight={500}>Inquiry {proposal?.id}</Body2>
                </ItemWrapper1>
                <ItemWrapper1>
                    <StatusChip label={friendlyStatusName} status={friendlyStatusName} bold />
                </ItemWrapper1>
                <ItemWrapper2>
                    <CalendarTodayOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Date:</Body2>
                    <Body2 fontWeight={500} minWidth="110px">
                        {formatDateRange(event?.startsAt || '', event?.endsAt || '')}
                    </Body2>
                </ItemWrapper2>
                <ItemWrapper2>
                    <PeopleAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Guests:</Body2>
                    <Body2>{guestCount}</Body2>
                </ItemWrapper2>

                {contact ? (
                    <ItemWrapper2>
                        <PersonAddAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                        <Body2 fontWeight={500}>
                            Assigned to: {`${contact.firstName ?? '-'} ${contact.lastName ?? ''}`}
                        </Body2>
                        <Body2
                            fontWeight={500}
                            color={EColors.bizlyOSPrimary}
                            style={{ cursor: 'pointer' }}
                            onClick={handleInviteOrAssign}
                        >
                            Edit
                        </Body2>
                    </ItemWrapper2>
                ) : (
                    <ItemWrapper2 style={{ cursor: 'pointer' }} onClick={handleInviteOrAssign}>
                        <PersonAddAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                        <Body2 fontWeight={500} color={EColors.bizlyOSPrimary}>
                            Assign
                        </Body2>
                    </ItemWrapper2>
                )}
            </Box>

            <AssignInquirySideDrawer drawerOpen={isAssignDrawerOpen} onClose={() => setIsAssignDrawerOpen(false)} />
            <InviteStaffDrawer
                drawerOpen={inviteColleagueOpen}
                setDrawerOpen={setInviteColleagueOpen}
                addMultiple={hasStaffMembers}
                addStaffByDefault={!hasStaffMembers}
            />
        </>
    );
};
