import { Box } from '@mui/material';
import { TESBooking } from 'components/ProposalForm/types';
import { formatCurrency } from 'utils/currency';
import { TextCard } from '../TextCard';

const FALLBACK_TEXT = '-';

export function EventSpacePricingData({
    useDdr,
    eventSpace,
    currencySymbol,
    totaleventSpaceCost,
}: {
    useDdr: boolean;
    eventSpace: TESBooking;
    currencySymbol: string;
    totaleventSpaceCost: number;
}) {
    return (
        <>
            {useDdr ? (
                <Box display="grid" gridTemplateColumns="1fr 1.5fr 1fr" gap={2.5}>
                    <TextCard
                        title="Per Person Rate"
                        description={
                            eventSpace.proposedRatePerPerson
                                ? formatCurrency({
                                      value: eventSpace.proposedRatePerPerson,
                                      symbol: currencySymbol,
                                      decimals: 0,
                                  })
                                : FALLBACK_TEXT
                        }
                    />
                    <TextCard
                        flex="none"
                        title="Minimum No. of Guests"
                        description={eventSpace.proposedMinGuests?.toString() || '-'}
                    />
                    <TextCard
                        title="Total"
                        description={
                            totaleventSpaceCost
                                ? formatCurrency({
                                      value: totaleventSpaceCost,
                                      symbol: currencySymbol,
                                      decimals: 0,
                                  })
                                : FALLBACK_TEXT
                        }
                    />
                </Box>
            ) : (
                <Box display="flex" gap={2.5}>
                    <TextCard
                        title="Daily Room Rate"
                        description={
                            eventSpace.proposedRoomRate
                                ? formatCurrency({
                                      value: eventSpace.proposedRoomRate,
                                      symbol: currencySymbol,
                                      decimals: 0,
                                  })
                                : FALLBACK_TEXT
                        }
                    />
                    <TextCard
                        title="Daily F&B Minimum"
                        description={
                            eventSpace.proposedFbMinimum || eventSpace.proposedFbMinimum === 0
                                ? formatCurrency({
                                      value: eventSpace.proposedFbMinimum,
                                      symbol: currencySymbol,
                                      decimals: 0,
                                  })
                                : FALLBACK_TEXT
                        }
                    />
                </Box>
            )}
        </>
    );
}
