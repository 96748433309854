import { useWithdrawProposal } from 'hooks/queries/BizlyOS/useInquiryActions';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProposalStatus } from '../InquiryAction';
import { InitiateExtendProposal } from './InitiateExtendProposal';
import { InitiateProposalWithdraw } from './IntitiateProposalWithdraw';
import { ProposalAccepted } from './ProposalAccepted';
import { ProposalExpired } from './ProposalExpired';
import { ProposalSent } from './ProposalSent';
import { ProposalWithdrawn } from './ProposalWithdrawn';
import { ProposalWon } from './ProposalWon';
import { WithdrawProposal } from './WithdrawProposal';
export const ProposalManager = ({ proposalStatus }: { proposalStatus: string }) => {
    const [status, setStatus] = useState(proposalStatus);

    const { venueId = '', proposalId = '' } = useParams<{ venueId?: string; proposalId?: string }>();

    const withdrawProposal = useWithdrawProposal(venueId, proposalId);

    const handleWithdrawalSubmission = async (withdrawalData: {
        cancellationReason: string;
        cancellationNotes: string;
    }) => {
        try {
            await withdrawProposal.mutate(withdrawalData);
            setStatus(ProposalStatus.WITHDRAWN);
        } catch (error) {
            console.error('Failed to withdraw proposal:', error);
        }
    };

    const startWithdrawProcess = () => {
        setStatus(ProposalStatus.WITHDRAWING);
    };

    const cancelWithdraw = () => {
        setStatus(ProposalStatus.SENT);
    };

    const confirmWithdrawIntent = () => {
        setStatus(ProposalStatus.WITHDRAWN);
    };

    const initiateExtendProposal = () => {
        setStatus(ProposalStatus.INITIATE_EXTEND_PROPOSAL);
    };

    const cancelExtendProposal = () => {
        setStatus(ProposalStatus.EXPIRED);
    };

    const confirmExtendProposal = () => {
        setStatus(ProposalStatus.SENT);
    };

    const RenderContent = () => {
        switch (status) {
            case ProposalStatus.INITIATING_WITHDRAWAL:
                return <InitiateProposalWithdraw confirmWithdrawIntent={confirmWithdrawIntent} />;

            case ProposalStatus.WITHDRAWING:
                return <WithdrawProposal onCancel={cancelWithdraw} onSubmit={handleWithdrawalSubmission} />;

            case ProposalStatus.WITHDRAWN:
                return <ProposalWithdrawn />;

            case ProposalStatus.INITIATE_EXTEND_PROPOSAL:
                return (
                    <InitiateExtendProposal
                        cancelExtendProposal={cancelExtendProposal}
                        confirmExtendProposal={confirmExtendProposal}
                    />
                );

            case ProposalStatus.EXPIRED:
                return (
                    <ProposalExpired
                        initiateExtendProposal={initiateExtendProposal}
                        startWithdrawProcess={startWithdrawProcess}
                    />
                );

            case ProposalStatus.ACCEPTED:
                return <ProposalAccepted venueId={venueId} proposalId={proposalId} />;

            case ProposalStatus.WON:
                return <ProposalWon venueId={venueId} proposalId={proposalId} />;

            default:
                return (
                    <ProposalSent
                        venueId={venueId}
                        proposalId={proposalId}
                        startWithdrawProcess={startWithdrawProcess}
                    />
                );
        }
    };

    return <RenderContent />;
};
