import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { InviteStaffDrawer } from 'components/BizlyOS/InviteStaffDrawer';
import { ProposalStatus } from 'components/BizlyOS/Proposals/InquiryOverview/InquiryAction';
import { AssignInquirySideDrawer } from 'components/BizlyOS/Proposals/ProposalInquryHeader/AssignInquirySideDrawer/AssignInquirySideDrawer';
import { DEFAULT_COMMISSION, getProposalNotValid } from 'components/BizlyOS/Proposals/utils';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { TProposalForm } from 'components/ProposalForm/types';
import { useSendProposal, useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useStaffQuery } from 'hooks/queries/BizlyOS/useStaffQuery';
import { useBizlySnackbar } from 'hooks/useBizlySnackbar';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { formatCurrency } from 'utils/currency';

const Container = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    padding: spacing(2.5),
}));

const Card = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    border: '.0313rem solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
}));

const CardRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.25),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const TotalCard = styled(Card)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureBlack),
}));

const CompleteMessage = styled(Body2)({
    '& > span': {
        fontWeight: 600,
        cursor: 'pointer',
    },
});

const Divider = styled(Box)(({ theme: { getColor, EColors } }) => ({
    width: 'calc(100% + 2.5rem)',
    transform: 'translateX(-1.25rem)',
    height: '.0313rem',
    backgroundColor: getColor(EColors.bizlyOSBorder),
}));

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSWarning, 0.1),
    gap: spacing(1.25),
}));

type ProposalSummaryBodyProps = {
    proposalInquiry: TProposalForm;
    currencySymbol: string;
    proposalTotal: number;
};

export function ProposalSummaryFooter({ proposalInquiry, currencySymbol, proposalTotal }: ProposalSummaryBodyProps) {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { mutate: sendProposal } = useSendProposal(venueId, proposalId);
    const { mutate: updateProposal, isLoading } = useUpdateProposalInquiry(venueId, proposalId);

    const { data: staffMembers } = useStaffQuery(venueId);

    const [isAssignDrawerOpen, setIsAssignDrawerOpen] = useState(false);
    const [inviteColleagueOpen, setInviteColleagueOpen] = useState(false);

    const { bizlyOsSnackbar, bizlyOSErrorSnackbar } = useBizlySnackbar();

    const isProposalNotValid = useMemo(() => getProposalNotValid(proposalInquiry), [proposalInquiry]);

    const isProposalSent = (proposalInquiry?.proposal?.status?.friendlyName || '') === ProposalStatus.ACCEPTED;
    const hasStaffMembers = staffMembers?.success && staffMembers?.staffMembers?.length > 0;

    const handleInviteOrAssign = () => {
        if (hasStaffMembers) {
            setIsAssignDrawerOpen(true);
        } else {
            setInviteColleagueOpen(true);
        }
    };

    const handleSendProposal = async () => {
        const { proposal } = proposalInquiry;
        const { guestRoomCommissionRate, eventSpaceCommissionRate, fbCommissionRate } = proposal;

        // Check if any commission rates need to be updated with defaults
        const needsCommissionUpdate = [guestRoomCommissionRate, eventSpaceCommissionRate, fbCommissionRate].some(
            rate => rate == null
        );

        if (needsCommissionUpdate) {
            const updatedProposal = {
                ...proposal,
                guestRoomCommissionRate: guestRoomCommissionRate ?? DEFAULT_COMMISSION,
                eventSpaceCommissionRate: eventSpaceCommissionRate ?? DEFAULT_COMMISSION,
                fbCommissionRate: fbCommissionRate ?? DEFAULT_COMMISSION,
            };

            updateProposal({ proposal: updatedProposal });
        }

        sendProposal(undefined, {
            onSuccess: () => bizlyOsSnackbar('Proposal Successfully Sent!'),
            onError: bizlyOSErrorSnackbar,
        });
    };

    return (
        <>
            <Container>
                <TotalCard>
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Proposal Total
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            {formatCurrency({
                                value: proposalTotal,
                                symbol: currencySymbol,
                                code: proposalInquiry.proposal.currency?.code,
                            })}
                        </Body2>
                    </CardRow>

                    {/* 
                    NOTE: Commented for now until the API is ready to handel deposit.
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Deposit (0%)
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow> 
                    */}
                </TotalCard>

                {!isProposalSent && isProposalNotValid && (
                    <InfoContainer>
                        <InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSWarning) }} />
                        <CompleteMessage>
                            Complete all fields
                            {!proposalInquiry.contact && (
                                <>
                                    {' '}
                                    and
                                    <span onClick={handleInviteOrAssign}> assign a Colleague</span>
                                </>
                            )}{' '}
                            to send your proposal
                        </CompleteMessage>
                    </InfoContainer>
                )}

                <Divider />

                {/* Todo: Update this status to reference the constant from the proposal manager */}
                <Button
                    fullWidth
                    onClick={handleSendProposal}
                    disabled={isProposalSent || isProposalNotValid}
                    loading={isLoading}
                >
                    {`${isProposalSent ? 'Proposal Sent' : 'Send Proposal'}`}
                </Button>
            </Container>
            <AssignInquirySideDrawer drawerOpen={isAssignDrawerOpen} onClose={() => setIsAssignDrawerOpen(false)} />
            <InviteStaffDrawer
                drawerOpen={inviteColleagueOpen}
                setDrawerOpen={setInviteColleagueOpen}
                addMultiple={hasStaffMembers}
                addStaffByDefault={!hasStaffMembers}
            />
        </>
    );
}
