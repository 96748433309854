import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';

export const InquiryExpired = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <Box display="flex" alignItems="center" gap={1.25}>
                <CancelIcon sx={{ color: getColor(EColors.warningText) }} />
                <Body1 fontWeight={600} sx={{ color: getColor(EColors.warningText) }}>
                    Inquiry Expired!
                </Body1>
            </Box>
            <Body2>
                We regret to inform you that this inquiry has expired. For future opportunities, we encourage prompt
                responses as timely communication often makes the difference in securing bookings
            </Body2>
        </Box>
    );
};
