import { Box, styled } from '@mui/material';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { TESBooking, TGRBooking, TProposal } from 'components/ProposalForm/types';
import { formatCurrency } from 'utils/currency';

const Body = styled(Box)(({ theme: { spacing } }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    overflowY: 'auto',
    padding: spacing(2.5),
}));

const Card = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    border: '.0313rem solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
}));

const CardRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.25),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const Title = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(1.25),
    borderBottom: '.0313rem solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 500,
}));

type ProposalSummaryBodyProps = {
    proposal: Partial<TProposal> | undefined;
    eventSpaces: TESBooking[];
    guestRooms: TGRBooking[];
    currencySymbol: string;
    currencyCode?: string;
    guestRoomsData: {
        roomRateTotal: number;
        occupancyTax: number;
        totalResortFee: number;
        roomblockTotal: number;
    };
    eventSpacesData: {
        eventSpacesRateTotal: number;
        eventSpaceFbMinimum: number;
        salesTax: number;
        serviceCharge: number;
        gratuity: number;
        eventSpacesTotal: number;
    };
};

export function ProposalSummaryBody({
    guestRoomsData,
    eventSpacesData,
    currencySymbol,
    currencyCode,
    proposal,
    guestRooms,
    eventSpaces,
}: ProposalSummaryBodyProps) {
    const { roomRateTotal, occupancyTax, totalResortFee, roomblockTotal } = guestRoomsData;
    const { eventSpacesRateTotal, eventSpaceFbMinimum, salesTax, serviceCharge, gratuity, eventSpacesTotal } =
        eventSpacesData;
    return (
        <Body>
            {guestRooms.length > 0 && (
                <Card>
                    <Title>Room Block</Title>
                    <CardRow>
                        <Body2>Rate</Body2>
                        <Body2>
                            {formatCurrency({
                                value: roomRateTotal,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Occupancy Tax ({proposal?.occupancyTax || 0}%)</Body2>
                        <Body2>
                            {formatCurrency({
                                value: occupancyTax,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Resort Fee</Body2>
                        <Body2>
                            {formatCurrency({
                                value: totalResortFee,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2 fontWeight={500}>Room Block Total</Body2>
                        <Body2 fontWeight={500}>
                            {formatCurrency({
                                value: roomblockTotal,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                </Card>
            )}

            {eventSpaces.length > 0 && (
                <Card>
                    <Title>Spaces</Title>
                    <CardRow>
                        <Body2>Rate</Body2>
                        <Body2>
                            {formatCurrency({
                                value: eventSpacesRateTotal,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    {!proposal?.useDdr && (
                        <CardRow>
                            <Body2>F&B Minimum</Body2>
                            <Body2>
                                {formatCurrency({
                                    value: eventSpaceFbMinimum,
                                    symbol: currencySymbol,
                                    code: currencyCode,
                                })}
                            </Body2>
                        </CardRow>
                    )}
                    <CardRow>
                        <Body2>Sales Tax ({proposal?.salesTax}%)</Body2>
                        <Body2>
                            {formatCurrency({
                                value: salesTax,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Service Charge ({proposal?.serviceCharge}%)</Body2>
                        <Body2>
                            {formatCurrency({
                                value: serviceCharge,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Gratuity ({proposal?.gratuity}%)</Body2>
                        <Body2>
                            {formatCurrency({
                                value: gratuity,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2 fontWeight={500}>Space Total</Body2>
                        <Body2 fontWeight={500}>
                            {formatCurrency({
                                value: eventSpacesTotal,
                                symbol: currencySymbol,
                                code: currencyCode,
                            })}
                        </Body2>
                    </CardRow>
                </Card>
            )}
        </Body>
    );
}
