import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { ProposalStatus } from '../InquiryAction';
import { DeclinedInquiryForm } from './DeclinedInquiryForm';
import { InquiryExpired } from './InquiryExpired';
import { NewInquiry } from './NewInquiry';
import { RejectedInquiry } from './RejectedInquiry';

export const RenderInquiryContent = ({
    status,
    handleConfirmDecline,
    handleInitiateDecline,
    handleBack,
    venueId,
    proposalId,
}: {
    status: string;
    handleConfirmDecline: (data: { rejectionNotes: string; rejectionReason: string }) => void;
    handleInitiateDecline: () => void;
    handleBack: () => void;
    venueId: string;
    proposalId: string;
}) => {
    switch (status) {
        case ProposalStatus.REJECTED:
            return <RejectedInquiry />;

        case ProposalStatus.DECLINING:
            return <DeclinedInquiryForm onSubmit={handleConfirmDecline} onCancel={handleBack} />;

        case ProposalStatus.NEW_INQUIRY:
            return (
                <NewInquiry handleInitiateDecline={handleInitiateDecline} venueId={venueId} proposalId={proposalId} />
            );

        case ProposalStatus.EXPIRED_INQUIRY:
            return <InquiryExpired />;

        default:
            return <Body2>{status}</Body2>;
    }
};
