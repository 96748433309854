export const formatCurrency = ({
    value,
    code,
    symbol = '$',
    locale,
    decimals = 2,
}: {
    value: number;
    symbol?: string;
    code?: string;
    locale?: string;
    decimals?: number;
}): string => {
    const userLocale = locale ? locale : Intl.NumberFormat().resolvedOptions().locale;
    const valueFormatter = new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        ...(code ? { currency: code } : {}),
    });

    const formatedValue = valueFormatter.format(value);

    return `${symbol}${formatedValue}`;
};
