import { Box, styled } from '@mui/material';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DEFAULT_CURRENCY_SYMBOL, calculateEventSpacesData, calculateGuestRoomsData } from '../../../utils';
import { ProposalSummaryBody } from './ProposalSummaryBody';
import { ProposalSummaryFooter } from './ProposalSummaryFooter';

export const Container = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

export function ProposalSummary() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const currencySymbol = proposalInquiry?.proposal?.currency?.symbol || DEFAULT_CURRENCY_SYMBOL;

    const guestRoomsData = useMemo(
        () => calculateGuestRoomsData(proposalInquiry?.guestRooms, proposalInquiry?.proposal),
        [proposalInquiry?.guestRooms, proposalInquiry?.proposal]
    );

    const eventSpacesData = useMemo(
        () => calculateEventSpacesData(proposalInquiry?.eventSpaces, proposalInquiry?.proposal),
        [proposalInquiry?.eventSpaces, proposalInquiry?.proposal]
    );

    if (isLoading || !proposalInquiry) return null;

    return (
        <Container>
            <ProposalSummaryBody
                guestRoomsData={guestRoomsData}
                eventSpacesData={eventSpacesData}
                currencySymbol={currencySymbol}
                proposal={proposalInquiry?.proposal}
                guestRooms={proposalInquiry?.guestRooms || []}
                eventSpaces={proposalInquiry?.eventSpaces || []}
                currencyCode={proposalInquiry?.proposal?.currency?.code}
            />
            <ProposalSummaryFooter
                proposalInquiry={proposalInquiry}
                currencySymbol={currencySymbol}
                proposalTotal={guestRoomsData.roomblockTotal + eventSpacesData.eventSpacesTotal}
            />
        </Container>
    );
}
